var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gumi-header"},[_c('div',{staticClass:"main-header d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between px-5"},[_c('div',{staticClass:"justify-content-center relative p-relative d-flex align-items-center col-lg-2 mb-2 mb-md-0 text-dark text-decoration-none"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"width":"150px","src":"/images_dios/logo_header.png"}})]),_vm._m(0)],1),(_vm.model && _vm.model.items)?_c('ul',{staticClass:"mainMenu nav col-lg-7 col-lg-auto mb-2 justify-content-center mb-md-0"},[_vm._l((_vm.model.items),function(item,index){return [_c('li',{key:'menu-1-' + index,class:{
                        active: _vm.$route.path === item.link
                    }},[_c('router-link',{staticClass:"nav-link link-dark menuElement",attrs:{"to":item.link}},[_vm._v(_vm._s(item.title))])],1)]})],2):_vm._e(),_c('div',{staticClass:"col-lg-3 menuIcon d-flex justify-content-between align-items-center mr"},[_vm._m(1),_vm._m(2),_c('i',{staticClass:"icon-search",attrs:{"id":"search"},on:{"click":_vm.openSearch}}),_c('i',{staticClass:"icon-basket",attrs:{"id":"cart"},on:{"click":_vm.openCart}},[(_vm.cartQuantity > 0)?_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.cartQuantity)+" ")]):_vm._e()]),_c('div',{staticClass:"search-items",class:{ hidden: _vm.showSearch }},[_c('div',{staticClass:"items-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.s),expression:"s"}],attrs:{"type":"text","name":"search","placeholder":"Keresés..."},domProps:{"value":(_vm.s)},on:{"keyup":_vm.startSearch,"input":function($event){if($event.target.composing){ return; }_vm.s=$event.target.value}}}),_vm._l((_vm.results),function(productData,index){return _c('router-link',{key:'results-' + index,staticClass:"search-item",staticStyle:{"cursor":"pointer"},attrs:{"tag":"div","to":{
                            name: 'Product',
                            params: {
                                slug: productData.kod2,
                                emarka: productData.emarka,
                                width: productData.width,
                                profile: productData.profile,
                                coll: productData.coll
                            }
                        }}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[(
                                        productData.globalPattern &&
                                            productData.globalPattern.image
                                    )?_c('img',{attrs:{"src":productData.globalPattern.image}}):_c('img',{attrs:{"src":productData.kepnev}})]),_c('div',{staticClass:"col-lg-8"},[_c('h3',{staticClass:"item-name"},[_vm._v(" "+_vm._s(productData.emarka)+" "+_vm._s(productData.pattern)+" "+_vm._s(productData.m1nev)+" "+_vm._s(productData.li)+" "+_vm._s(productData.si)+" "+_vm._s(productData.xlfr)+" ")]),_c('span',{staticClass:"item-type"},[_vm._v(_vm._s(productData.width)+"/"+_vm._s(productData.profile)+"R"+_vm._s(productData.coll))]),(
                                        productData.tat <= 6 &&
                                            productData.tat !== 0
                                    )?_c('span',{staticClass:"item-quantity"},[_vm._v(_vm._s(productData.tat)+" db")]):_vm._e(),_c('span',{staticClass:"item-price sale",class:{
                                        underlined: productData.szemar !== 0
                                    }},[_vm._v(" Ár: "+_vm._s(productData.calculated_special_price)+" / db ")])])])])})],2)]),_c('div',{staticClass:"shopping-cart",class:{ hidden: _vm.showCart }},[(_vm.cart && Object.keys(_vm.cart).length > 0)?_c('div',{staticClass:"shopping-cart-items"},[_vm._l((_vm.cart.items),function(item,index){return _c('div',{key:'cart-' + index,staticClass:"cart-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[(
                                        item.product.globalPattern &&
                                            item.product.globalPattern.image
                                    )?_c('img',{attrs:{"src":item.product.globalPattern.image}}):_c('img',{attrs:{"src":item.product.kepnev}})]),_c('div',{staticClass:"col-lg-8"},[_c('h3',{staticClass:"item-name"},[_vm._v(" "+_vm._s(item.product.emarka)+" "+_vm._s(item.product.pattern)+" "+_vm._s(item.product.m1nev)+" "+_vm._s(item.product.li)+" "+_vm._s(item.product.si)+" "+_vm._s(item.product.xlfr)+" ")]),_c('span',{staticClass:"item-type"},[_vm._v(_vm._s(item.product.width)+"/"+_vm._s(item.product.profile)+"R"+_vm._s(item.product.coll))]),_c('span',{staticClass:"item-quantity"},[_vm._v(_vm._s(item.quantity)+" db")]),_c('span',{staticClass:"item-price sale",class:{
                                        underlined:
                                            item.product.szemar !== 0
                                    }},[_vm._v(" Ár: "+_vm._s(item.product .calculated_special_price)+" / db ")])])])])}),_c('div',{staticClass:"row totals"},[_c('span',{staticClass:"totalText col-lg-6"},[_vm._v("Összesen")]),_c('span',{staticClass:"totalAmount col-lg-6"},[_vm._v(_vm._s(_vm.cart.cart_total.total_f)+" Ft")])]),_c('router-link',{staticClass:"transition",attrs:{"tag":"button","to":{ name: 'Cart' },"id":"mycart"}},[_vm._v("Kosaram")]),_c('router-link',{staticClass:"transition",attrs:{"to":{ name: 'CheckoutBilling' },"tag":"button","id":"checkout"}},[_vm._v(" Kasszához ")])],2):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extraDiv"},[_c('button',{staticClass:"extraButton",attrs:{"onclick":"window.open('https://gumiszervizem.hu/szervizeink/', '_blank');","target":"_blank"}},[_vm._v(" Online időpontfoglalás ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.point-s.hu/","target":"_blank"}},[_c('img',{staticClass:"menuElement",attrs:{"height":"86px","src":"/images_dios/point-s-katyugarancia-logo.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.point-s.hu/","target":"_blank"}},[_c('img',{staticClass:"menuElement",attrs:{"width":"190px","src":"/images_dios/point_s_logo.png"}})])}]

export { render, staticRenderFns }