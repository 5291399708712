import VueCookies from "vue-cookies";
import Vue from "vue";
Vue.use(VueCookies);

const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
    return Vue.$cookies.get(ID_TOKEN_KEY);
};

export const getXSRFToken = () => {
    return Vue.$cookies.get("XSRF-TOKEN");
};

export const saveToken = token => {
    if (token) {
        Vue.$cookies.set(ID_TOKEN_KEY, token, "1d");
    }
};

export const destroyToken = () => {
    Vue.$cookies.remove(ID_TOKEN_KEY);
};

export default { getToken, getXSRFToken, saveToken, destroyToken };
