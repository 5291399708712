<template>
    <div id="app" v-bind:class="{ nosearch: !showSearch }">
        <Header />
        <Menu />
        <TyreSearch v-if="showSearch" />
        <router-view />
        <Footer />
        <!-- Modal -->
        <b-modal id="modal-1" hide-header hide-header-close hide-footer>
            <div class="my-4">
                <p style="margin-left:0cm; margin-right:0cm">
                    <span style="font-family:Arial,Helvetica,sans-serif"
                        ><em
                            ><strong
                                ><span style="font-size:12pt"
                                    >A Point-S kátyúgarancia - kényelmes és
                                    ingyenes, áttekinthető és egyszerű</span
                                ></strong
                            ></em
                        ></span
                    >
                </p>
                <p style="margin-left:0cm; margin-right:0cm">
                    <span style="font-family:Arial,Helvetica,sans-serif"
                        ><span style="font-size:12pt"
                            >Ez a garancia Magyarország területén érvényes és
                            minden Point-S szakszervizben igénybe vehető,
                            függetlenül attól, hogy melyik Point-S
                            kereskedőjénél vásárolta abroncsát. A Point-S
                            abroncsgaranciája kiegészíti a szokásos gyártói
                            garanciát, amely kizárólag az abroncsok
                            gyártáshiba-mentességre vonatkozik. A garancia az
                            abroncs vásárlása napján lép életbe és pontosan 12
                            hónap múlva jár le.
                        </span></span
                    >
                </p>
                <p style="margin-left:0cm; margin-right:0cm">
                    <span style="font-family:Arial,Helvetica,sans-serif"
                        ><span style="font-size:12pt"
                            >Amiben ez a biztosítás más, mint a többi: ez a
                            szolgáltatás és Önnek egy fillérjébe sem kerül! Az
                            Ön számlája a vásárlás dátumával, egységárral és
                            Kátyúgarancia weboldalon történt
                            <a
                                href="http://www.katyugarancia.hu/regisztracio"
                                style="color:blue; text-decoration:underline"
                                title="Kátyúgarancia regisztráció"
                                >regisztrációt</a
                            >
                            követően letölthető garanciajeggyel, automatikusan
                            bizonyítékul szolgál garancia esetéhez. Ön a
                            garantált pótlást 12 hónapig kapja egy, a garancia
                            feltételnek is megfelelő kár esetében. A
                            garanciaösszeget nem készpénzben fizetjük ki, hanem
                            térítésmentesen kicseréljük azt, egy ugyanolyan
                            abroncsra, ami részt vesz a kátyúgarancia
                            szolgáltatásban. A szerelési költséget nem
                            tartalmazza a garancia. A jóváírás nagysága a
                            maradék mintázati mélységtől függ.</span
                        ></span
                    >
                </p>
                <p style="margin-left:0cm; margin-right:0cm">
                    <span style="font-family:Arial,Helvetica,sans-serif"
                        ><span style="font-size:12pt"
                            >A kátyúgarancia időtartama a gumiabroncs
                            vásárlásától számított egy év, illetve legfeljebb az
                            1,6 mm profilmélység eléréséig érvényes.</span
                        ></span
                    >
                </p>
                <h4 style="margin-left:0cm; margin-right:0cm">&nbsp;</h4>
                <h4 style="margin-left:0cm; margin-right:0cm">
                    <span style="font-family:Arial,Helvetica,sans-serif"
                        ><span style="font-size:16px"
                            ><strong>Mi az ügyfél tennivalója?</strong></span
                        ></span
                    >
                </h4>
                <ul>
                    <li>
                        <span style="font-family:Arial,Helvetica,sans-serif"
                            ><span style="font-size:11pt"
                                >Vásároljon egy garnitúra (4 db) gumiabroncsot
                                bármely Point- S szakszervizben a megjelölt
                                márkák közül!</span
                            ></span
                        >
                    </li>
                    <li>
                        <span style="font-family:Arial,Helvetica,sans-serif"
                            ><span style="font-size:11pt"
                                >Őrizze meg az abroncsa számláját!</span
                            ></span
                        >
                    </li>
                    <li>
                        <span style="font-family:Arial,Helvetica,sans-serif"
                            ><span style="font-size:11pt"
                                >Regisztrálja be a vásárlását a
                                <strong
                                    ><a
                                        href="http://www.katyugarancia.hu/regisztracio"
                                        style="color:blue; text-decoration:underline"
                                        >www.katyugarancia.hu/regisztracio</a
                                    ></strong
                                >
                                oldalon</span
                            ></span
                        >
                    </li>
                </ul>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Header from "@/components/Header";
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import TyreSearch from "@/components/TyreSearch";
export default {
    components: { TyreSearch, Footer, Menu, Header },
    computed: {
        showSearch: function() {
            if (
                this.$route.name === "Login" ||
                this.$route.name === "Blog" ||
                this.$route.name === "BlogPost" ||
                this.$route.name === "Registration" ||
                this.$route.name === "ForgetPassword" ||
                this.$route.name === "LostPassword" ||
                this.$route.name === "ProfileData" ||
                this.$route.name === "Profile" ||
                this.$route.name === "MyOrders" ||
                this.$route.name === "ProfileNewsletter" ||
                this.$route.name === "Cart" ||
                this.$route.name === "CheckoutBilling" ||
                this.$route.name === "CheckoutShipping" ||
                this.$route.name === "CheckoutPayment" ||
                this.$route.name === "CheckoutFinish" ||
                this.$route.name === "CheckoutSuccess" ||
                this.$route.name === "Static"
            ) {
                return false;
            }

            return true;
        }
    }
};
</script>

<style>
.toast-close-button {
    display: block !important;
    width: auto !important;
}
</style>
