import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ApiService from "@/core/services/api.service";
import { IS_AUTH } from "@/core/services/store/auth.module";
import mixins from "./mixins";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueCookies from "vue-cookies";
import jQuery from "jquery";
import i18n from "@/core/plugins/vue-i18n";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/generated/locator.css";
import "@/assets/custom.css";
import "toastr/build/toastr.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

Vue.config.productionTip = false;
Vue.prototype.$translate = i18n;
Vue.prototype.$eventHub = new Vue();
Vue.prototype.$ = jQuery;
window.$ = jQuery;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.mixin(mixins);

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
    // Ensure we checked auth before each page load.
    if (to.meta.auth) {
        Promise.all([store.dispatch(IS_AUTH)])
            .then(resp => {
                if (resp[0]) {
                    next();
                } else {
                    router.push({ name: "Home" });
                }
            })
            .catch(() => {
                router.push({ name: "Home" });
            });
    } else {
        next();
    }

    // Scroll page to top on every route change
    /*setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);*/
});

const DEFAULT_TITLE = process.env.VUE_APP_APP_NAME;
router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

new Vue({
    router,
    store,
    i18n,
    VueCookies,
    render: h => h(App)
}).$mount("#app");
