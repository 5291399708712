var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gumi-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mobile-header d-flex flex-wrap align-items-center py-3"},[_c('router-link',{staticClass:"d-flex align-items-center mb-2 text-dark text-decoration-none",attrs:{"to":"/"}},[_c('img',{staticClass:"header-logo",attrs:{"src":"/images_dios/logo_header.png"}})]),_c('div',{staticClass:"flexrow align-items-center hamburger-holder"},[_c('img',{staticClass:"menuElement points",attrs:{"src":"/images_dios/point_s_logo.png"}}),_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"icon-menu"})]),_c('div',{staticClass:"menuIcon"},[_c('router-link',{staticClass:"icon-basket",attrs:{"tag":"i","id":"cart","to":{ name: 'Cart' }}},[(_vm.cartQuantity > 0)?_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.cartQuantity)+" ")]):_vm._e()])],1)]),_c('div',{class:{ showMenu: _vm.showMenu },attrs:{"id":"hamburgerLinks"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.s),expression:"s"}],staticClass:"hamburger-search",attrs:{"type":"text","name":"search","placeholder":"Keresés..."},domProps:{"value":(_vm.s)},on:{"input":function($event){if($event.target.composing){ return; }_vm.s=$event.target.value}}}),_c('button',{attrs:{"id":"startSearch"},on:{"click":_vm.startSearch}},[_c('i',{staticClass:"icon-search"})]),_c('div',{staticClass:"search-items",class:{
                        hidden: _vm.showSearch === false
                    },attrs:{"id":"mobile-search-container"}},[_c('div',{staticClass:"items-box"},_vm._l((_vm.results),function(productData,index){return _c('router-link',{key:'results-' + index,staticClass:"search-item",staticStyle:{"cursor":"pointer"},attrs:{"tag":"div","to":{
                                name: 'Product',
                                params: {
                                    slug: productData.kod2,
                                    emarka: productData.emarka,
                                    width: productData.width,
                                    profile: productData.profile,
                                    coll: productData.coll
                                }
                            }}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[(
                                            productData.globalPattern &&
                                                productData.globalPattern
                                                    .image
                                        )?_c('img',{attrs:{"src":productData.globalPattern.image}}):_c('img',{attrs:{"src":productData.kepnev}})]),_c('div',{staticClass:"col-lg-8"},[_c('h3',{staticClass:"item-name"},[_vm._v(" "+_vm._s(productData.emarka)+" "+_vm._s(productData.pattern)+" "+_vm._s(productData.m1nev)+" ")]),_c('span',{staticClass:"item-type"},[_vm._v(_vm._s(productData.width)+"/"+_vm._s(productData.profile)+"R"+_vm._s(productData.coll))]),_c('span',{staticClass:"item-quantity"},[_vm._v(_vm._s(productData.tat)+" db")]),_c('span',{staticClass:"item-price sale",class:{
                                            underlined:
                                                productData.szemar !== 0
                                        }},[_vm._v(" Ár: "+_vm._s(productData.calculated_special_price)+" / db ")])])])])}),1)]),(_vm.model && _vm.model.items)?_c('ul',[_vm._l((_vm.model.items),function(item,index){return [_c('li',{key:'menu-1-' + index,class:{
                                active: _vm.$route.path === item.link
                            }},[_c('router-link',{staticClass:"nav-link link-dark menuElement",attrs:{"to":item.link}},[_vm._v(_vm._s(item.title))])],1)]})],2):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }