<template>
    <div class="gumi-header">
        <div class="container">
            <div class="mobile-header d-flex flex-wrap align-items-center py-3">
                <router-link
                    to="/"
                    class="d-flex align-items-center mb-2 text-dark text-decoration-none"
                >
                    <img
                        class="header-logo"
                        src="/images_dios/logo_header.png"
                    />
                </router-link>
                <div class="flexrow align-items-center hamburger-holder">
                    <img
                        class="menuElement points"
                        src="/images_dios/point_s_logo.png"
                    />
                    <a
                        href="javascript:void(0);"
                        class=""
                        v-on:click="toggleMenu"
                    >
                        <i class="icon-menu"></i>
                    </a>
                    <div class="menuIcon">
                        <router-link
                            tag="i"
                            id="cart"
                            class="icon-basket"
                            :to="{ name: 'Cart' }"
                        >
                            <div class="count" v-if="cartQuantity > 0">
                                {{ cartQuantity }}
                            </div>
                        </router-link>
                    </div>
                </div>
                <div id="hamburgerLinks" v-bind:class="{ showMenu: showMenu }">
                    <input
                        type="text"
                        name="search"
                        class="hamburger-search"
                        placeholder="Keresés..."
                        v-model="s"
                    />
                    <button id="startSearch" v-on:click="startSearch">
                        <i class="icon-search"></i>
                    </button>
                    <div
                        id="mobile-search-container"
                        class="search-items"
                        v-bind:class="{
                            hidden: showSearch === false
                        }"
                    >
                        <div class="items-box">
                            <router-link
                                tag="div"
                                class="search-item"
                                style="cursor: pointer"
                                v-for="(productData, index) in results"
                                v-bind:key="'results-' + index"
                                :to="{
                                    name: 'Product',
                                    params: {
                                        slug: productData.kod2,
                                        emarka: productData.emarka,
                                        width: productData.width,
                                        profile: productData.profile,
                                        coll: productData.coll
                                    }
                                }"
                            >
                                <div class="row">
                                    <div class="col-lg-4">
                                        <img
                                            v-if="
                                                productData.globalPattern &&
                                                    productData.globalPattern
                                                        .image
                                            "
                                            :src="
                                                productData.globalPattern.image
                                            "
                                        />
                                        <img v-else :src="productData.kepnev" />
                                    </div>
                                    <div class="col-lg-8">
                                        <h3 class="item-name">
                                            {{ productData.emarka }}
                                            {{ productData.pattern }}
                                            {{ productData.m1nev }}
                                        </h3>
                                        <span class="item-type"
                                            >{{ productData.width }}/{{
                                                productData.profile
                                            }}R{{ productData.coll }}</span
                                        >
                                        <span class="item-quantity"
                                            >{{ productData.tat }} db</span
                                        >
                                        <span
                                            class="item-price sale"
                                            v-bind:class="{
                                                underlined:
                                                    productData.szemar !== 0
                                            }"
                                        >
                                            Ár:
                                            {{
                                                productData.calculated_special_price
                                            }}
                                            / db
                                        </span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <ul v-if="model && model.items">
                        <template v-for="(item, index) in model.items">
                            <li
                                v-bind:class="{
                                    active: $route.path === item.link
                                }"
                                v-bind:key="'menu-1-' + index"
                            >
                                <router-link
                                    :to="item.link"
                                    class="nav-link link-dark menuElement"
                                    >{{ item.title }}</router-link
                                >
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Menu",
    data() {
        return {
            model: {},
            cart: {},
            s: null,
            results: [],
            showSearch: false,
            showCart: true,
            timeOut: null,
            showMenu: false
        };
    },
    watch: {
        $route: {
            handler: function() {
                this.showMenu = false;
            },
            deep: true
        }
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        },
        cartQuantity: function() {
            let qty = 0;
            if (this.cart && this.cart.items) {
                for (const it in this.cart.items) {
                    qty += this.cart.items[it].quantity;
                }
            }

            return qty;
        }
    },
    beforeMount() {
        this.getSearchData();
        this.getCart();
    },
    mounted() {
        const self = this;
        self.$eventHub.$on("updateCart", function() {
            self.getCart();
        });

        document.addEventListener("mouseup", function(e) {
            let search_container = document.getElementById(
                "mobile-search-container"
            );
            let search_btn = document.getElementById("mobile-search-container");
            if (
                !search_btn.contains(e.target) ||
                !search_container.contains(e.target)
            ) {
                self.showSearch = false;
            }
        });
    },
    methods: {
        startSearch() {
            const self = this;
            clearTimeout(self.timeOut);
            self.timeOut = setTimeout(function() {
                if (self.s === null || self.s === "") {
                    self.results = [];
                } else {
                    self.showSearch = true;
                    ApiService.query(
                        `${self.url}/products/search-product?s=${self.s}`
                    ).then(response => {
                        self.results = response.data.data;
                    });
                }
            }, 500);
        },
        openCart() {
            this.showCart = !this.showCart;
            this.showSearch = true;
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        getSearchData() {
            ApiService.get(`${this.url}/menu/get/1`).then(response => {
                this.model = response.data.data;
            });
        },
        getCart() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.query(`${this.url}/cart?uuid=${cartUuid}`).then(
                response => {
                    $cookies.set("cart_uuid", response.data.data.uuid, 864000);
                    this.cart = response.data.data;
                }
            );
        }
    }
};
</script>

<style scoped>
.showMenu {
    display: block !important;
}
</style>
