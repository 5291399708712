import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const IS_AUTH = "isAuth";

const state = {
    errors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken()
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/sanctum/csrf-cookie`).then(() => {
                ApiService.post("/login", credentials)
                    .then(({ data }) => {
                        // console.log("Here what post returns", data);
                        context.commit(SET_AUTH, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response.data.errors);
                        context.commit(SET_ERROR, response.data.errors);
                    });
            });
        });
    },
    [LOGOUT](context) {
        return new Promise(resolve => {
            ApiService.post("/logout")
                .then(({ data }) => {
                    context.commit(PURGE_AUTH);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [REGISTER](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("/login", credentials)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [VERIFY_AUTH](context) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("/verify")
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                })
                .catch(({ response }) => {
                    context.commit(PURGE_AUTH);
                    context.commit(SET_ERROR, response.data.errors);
                });
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    [IS_AUTH](context) {
        if (JwtService.getToken()) {
            return true;
        }
        context.commit(PURGE_AUTH);
        return false;
    },
    [UPDATE_PASSWORD](context, payload) {
        const password = payload;

        return ApiService.put("/password", password).then(({ data }) => {
            context.commit(SET_PASSWORD, data);
            return data;
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user;
        state.errors = {};
        if (state.user.token) {
            JwtService.saveToken(state.user.token);
        }
    },
    [SET_PASSWORD](state, password) {
        state.user.password = password;
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
