var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mainSection filterBoxMain",class:{
        summerseason:
            this.Homepage_model.season &&
            this.Homepage_model.season.settings_value === 'nyárigumi'
    }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"filterBoxContainer"},[_c('b-tabs',{class:{
                            changecalculatoractive: _vm.changecalculator_state
                        },attrs:{"content-class":"filterbox "}},[_c('b-tab',{attrs:{"title":"Gumikereső","title-link-class":"filterbtn transition flex-item nav-link"},on:{"click":function($event){return _vm.ChangeCalculator_active(false)}}},[(
                                    _vm.filters &&
                                        Object.keys(_vm.filters).length > 0 &&
                                        Object.keys(_vm.filters.emarka).length >
                                            0
                                )?_c('Tyre',{attrs:{"filters":_vm.filters,"form":_vm.form},on:{"goToListPage":_vm.goToListPage}}):_vm._e()],1),_c('b-tab',{attrs:{"title":"Váltóméret kalkulátor","title-link-class":"filterbtn transition flex-item nav-link"},on:{"click":function($event){return _vm.ChangeCalculator_active(true)}}},[_c('ChangeCalculator',{attrs:{"filters":_vm.filters,"form":_vm.form},on:{"goToListPage":_vm.goToListPage}})],1)],1)],1)])]),_vm._m(0)]),_c('img',{staticClass:"bmw",attrs:{"src":"/images_dios/BMW.png","height":"360px"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex fancy"},[_c('div',{staticClass:"fancyBox gumikereso"},[_c('div',{staticClass:"w-10"},[_c('a',{attrs:{"href":"https://portal.alcar-wheels.com/wheelsportal/59761/start","target":"_blank"}},[_c('img',{attrs:{"src":"/images_dios/felni.png","width":"100px","height":"100px"}})])]),_c('div',{staticClass:"text-align-left fancybox-w90"},[_c('a',{attrs:{"href":"https://portal.alcar-wheels.com/wheelsportal/59761/start","target":"_blank"}},[_c('span',[_vm._v("Felni kereső")]),_vm._v(" > ")])]),_c('div',{staticClass:"inline-block"},[_c('i',{staticClass:"icon-arrow"}),_c('i',{staticClass:"icon-arrow"})])])])}]

export { render, staticRenderFns }