<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <h2>Váltóméret kalkulátor</h2>
            </div>
        </div>
        <div class="calculator-content">
            <div class="row">
                <div class="col-md-6">
                    <h4>Gyári méret</h4>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3">
                    <label class="col-md-6 verticalMiddle">Szélesség</label>
                    <div class="inputDiv icon-szelesseg">
                        <select
                            class="form-control form-select"
                            v-model="form.width"
                        >
                            <option :value="null">-</option>
                            <option
                                v-for="(item, index) in filters.width"
                                v-bind:key="'width-' + index"
                            >
                                {{ item }}</option
                            >
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-6 verticalMiddle">Magasság</label>
                    <div class="inputDiv icon-magassag">
                        <select
                            class="form-control form-select"
                            v-model="form.profile"
                        >
                            <option :value="null">-</option>
                            <option
                                v-for="(item, index) in filters.profile"
                                v-bind:key="'profile-' + index"
                            >
                                {{ item }}</option
                            >
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-6 verticalMiddle">Átmérő</label>
                    <div class="inputDiv icon-atmero">
                        <select
                            class="form-control form-select"
                            v-model="form.coll"
                        >
                            <option :value="null">-</option>
                            <option
                                v-for="(item, index) in filters.coll"
                                v-bind:key="'coll-' + index"
                            >
                                {{ item }}</option
                            >
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-6 verticalMiddle">mm</label>
                    <div class="inputDiv">
                        <input
                            v-model="elsoKulonbseg"
                            type="text"
                            readonly="readonly"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-12">
                    <label class="col-md-6 mb-2 verticalMiddle"
                        >Az abroncs átmérőkülönbsége</label
                    >
                    <div class="inputDiv">
                        <input
                            type="text"
                            readonly="readonly"
                            v-model="kettoKulonbseg"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h4>Váltó méret</h4>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3">
                    <label class="col-md-6 verticalMiddle">Szélesség</label>
                    <div class="inputDiv icon-szelesseg">
                        <select
                            class="form-control form-select"
                            v-model="to.width"
                        >
                            <option :value="null">-</option>
                            <option
                                v-for="(item, index) in filters.width"
                                v-bind:key="'width-' + index"
                            >
                                {{ item }}</option
                            >
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-6 verticalMiddle">Magasság</label>
                    <div class="inputDiv icon-magassag">
                        <select
                            class="form-control form-select"
                            v-model="to.profile"
                        >
                            <option :value="null">-</option>
                            <option
                                v-for="(item, index) in filters.profile"
                                v-bind:key="'profile-' + index"
                            >
                                {{ item }}</option
                            >
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-6 verticalMiddle">Átmérő</label>
                    <div class="inputDiv icon-atmero">
                        <select
                            class="form-control form-select"
                            v-model="to.coll"
                        >
                            <option :value="null">-</option>
                            <option
                                v-for="(item, index) in filters.coll"
                                v-bind:key="'coll-' + index"
                            >
                                {{ item }}</option
                            >
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-6 verticalMiddle">mm</label>
                    <div class="inputDiv">
                        <input
                            type="text"
                            v-model="masodKulonbseg"
                            readonly="readonly"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-12">
                    <label class="col-md-6 mb-2 verticalMiddle"
                        >A sebesség mutató 100Km/óra valós sebességnél, ennyit
                        fog mutatni:</label
                    >
                    <div class="inputDiv">
                        <input
                            type="text"
                            v-model="realSpeed"
                            readonly="readonly"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button
                        class="button filterButton transition"
                        v-on:click="goToListPage"
                    >
                        Keresés méretek alapján
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChangeCalculator",
    props: ["filters", "form"],
    data() {
        return {
            to: {
                width: null,
                profile: null,
                coll: null
            }
        };
    },
    computed: {
        elsoKulonbseg: function() {
            const helper = Math.round(
                parseFloat(this.form.width) *
                    (parseFloat(this.form.profile) / 100) *
                    2 +
                    this.form.coll * 25.4
            );
            if (!isNaN(helper)) {
                return helper;
            }

            return null;
        },
        masodKulonbseg: function() {
            const helper = Math.round(
                parseFloat(this.to.width) *
                    (parseFloat(this.to.profile) / 100) *
                    2 +
                    this.to.coll * 25.4
            );
            if (!isNaN(helper)) {
                return helper;
            }

            return null;
        },
        kettoKulonbseg: function() {
            if (!isNaN(this.elsoKulonbseg) && !isNaN(this.masodKulonbseg)) {
                return this.masodKulonbseg - this.elsoKulonbseg;
            }
            return null;
        },
        realSpeed: function() {
            const helper = this.masodKulonbseg / this.elsoKulonbseg;
            if (!isNaN(helper) && isFinite(helper)) {
                return Math.round(helper * 100);
            }

            return 100;
        }
    },
    methods: {
        goToListPage() {
            if (this.to.width && this.to.profile && this.to.coll) {
                this.$router.push({
                    name: "ProductList",
                    params: {
                        slug:
                            this.to.width +
                            "-" +
                            this.to.profile +
                            "-" +
                            this.to.coll
                    },
                    query: {
                        emarka: null,
                        m1nev: this.form.m1nev,
                        m2nev: this.form.m2nev,
                        si: this.form.si,
                        li: this.form.li,
                        defektt: this.form.defektt,
                        xlfr: this.form.xlfr,
                        tat: this.form.tat
                    }
                });
            } else {
                this.$emit("goToListPage", true);
            }
        }
    }
};
</script>

<style scoped></style>
