import Vue from "vue";
import Vuex from "vuex";

export const SET_LANGUAGE = "setLanguage";
export const SET_ERRORS = "setErrors";
export const SET_MODEL = "setModel";
export const SET_CURRENCY = "setPageCurrency";

export const SET_LANGUAGE_MUTATION = "setLanguageMutation";
export const SET_ERRORS_MUTATION = "setErrorsMutation";
export const SET_MODEL_MUTATION = "setModelMutation";
export const SET_CURRENCY_MUTATION = "setPageCurrencyMutation";

Vue.use(Vuex);

const state = {
    errors: [],
    languages: {
        hu: {},
        en: {}
    },
    pageCurrency: "1",
    model: {}
};

const actions = {
    [SET_LANGUAGE](context, payload) {
        context.commit(SET_LANGUAGE_MUTATION, payload);
    },
    [SET_ERRORS](context, payload) {
        context.commit(SET_ERRORS_MUTATION, payload);
    },
    [SET_MODEL](context, payload) {
        context.commit(SET_MODEL_MUTATION, payload);
    },
    [SET_CURRENCY](context, payload) {
        context.commit(SET_CURRENCY_MUTATION, payload);
    }
};

const mutations = {
    [SET_LANGUAGE_MUTATION](state, payload) {
        state.languages[payload.lang] = payload.payload;
    },
    [SET_ERRORS_MUTATION](state, payload) {
        state.errors = payload;
    },
    [SET_MODEL_MUTATION](state, payload) {
        state.model = payload;
    },
    [SET_CURRENCY_MUTATION](state, payload) {
        state.pageCurrency = payload;
    }
};

const getters = {
    getErrors(state) {
        return state.errors;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
