<template>
    <div class="row">
        <div class="col-xl-6">
            <div class="row">
                <div class="col-md-6">
                    <h2>Keresés méret szerint</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-2">
                        <label class="col-md-6 verticalMiddle">Szélesség</label>
                        <div class="inputDiv icon-szelesseg">
                            <select
                                class="form-control form-select"
                                v-model="form.width"
                            >
                                <option :value="null">-</option>
                                <option
                                    v-for="(item, index) in filters.width"
                                    v-bind:key="'width-' + index"
                                >
                                    {{ item }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="col-md-6 verticalMiddle">Magasság</label>
                        <div class="inputDiv icon-magassag">
                            <select
                                class="form-control form-select"
                                v-model="form.profile"
                            >
                                <option :value="null">-</option>
                                <option
                                    v-for="(item, index) in filters.profile"
                                    v-bind:key="'profile-' + index"
                                >
                                    {{ item }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="col-md-6 verticalMiddle">Átmérő</label>
                        <div class="inputDiv icon-atmero">
                            <select
                                class="form-control form-select"
                                v-model="form.coll"
                            >
                                <option :value="null">-</option>
                                <option
                                    v-for="(item, index) in filters.coll"
                                    v-bind:key="'coll-' + index"
                                >
                                    {{ item }}</option
                                >
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-2">
                        <label class="col-md-6 verticalMiddle">Évszak</label>
                        <div class="inputDiv icon-evszak">
                            <select
                                class="form-control  form-select"
                                v-model="form.m1nev"
                            >
                                <option :value="null">-</option>
                                <option
                                    v-for="(item, index) in filters.m1nev"
                                    v-bind:key="'profile-' + index"
                                >
                                    {{ item }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="col-md-6 verticalMiddle"
                            >Jármű típus</label
                        >
                        <div class="inputDiv icon-jarmu">
                            <select
                                class="form-control form-select"
                                v-model="form.m2nev"
                            >
                                <option :value="null">-</option>
                                <option
                                    v-for="(item, index) in filters.m2nev"
                                    v-bind:key="'profile-' + index"
                                    :value="index"
                                >
                                    {{ item }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="col-md-6 verticalMiddle">Márka</label>
                        <div class="inputDiv icon-marka">
                            <multiselect
                                v-if="
                                    filters &&
                                        filters.emarka &&
                                        filters.emarka.length > 0
                                "
                                v-model="form.emarka"
                                :options="filters.emarka"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                placeholder="Márka választás"
                                label="emarka"
                                track-by="emarka"
                                selectLabel=""
                                selectedLabel="Kiválasztva"
                                deselectLabel=""
                                :preselect-first="false"
                            >
                                <!--<template
                                    slot="selection"
                                    slot-scope="{ values, search, isOpen }"
                                    ><span
                                        class="multiselect__single form-select"
                                        v-if="values.length && !isOpen"
                                        >{{ values.length }} márka kiválasztva</span
                                    ></template
                                >-->
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row radios">
                <div class="col-md-6 verticalMiddle">
                    <label class="d-block">
                        <input
                            type="checkbox"
                            class="form-check-input transition"
                            name="xlfr"
                            v-model="form.xlfr"
                        />
                        Erősített oldalfal (XL)
                    </label>
                    <label class="d-block">
                        <input
                            type="checkbox"
                            name="defektt"
                            class="form-check-input transition"
                            v-model="form.defektt"
                        />
                        Defekttűrő
                    </label>
                </div>
                <div class="col-md-6">
                    <button
                        class="button filterButton transition"
                        v-on:click="goToListPage"
                    >
                        Keresés méretek alapján
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

export default {
    name: "Tyre",
    props: ["filters", "form"],
    components: { multiselect: Multiselect },
    data() {
        return {
            Homepage_model: {}
        };
    },
    methods: {
        goToListPage() {
            this.$emit("goToListPage", true);
        },
        getHomepageData() {
            ApiService.get(`${this.url}/page/home`).then(response => {
                this.Homepage_model = response.data;
                if (this.Homepage_model.season && this.Homepage_model.season.settings_value) {
                    this.form.m1nev = this.Homepage_model.season.settings_value;
                }
            });
        }
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    },
    beforeMount() {
        this.getHomepageData();
    },
    mounted() {
        this.form.width = null;
        this.form.profile = null;
        this.form.coll = null;
        this.form.m1nev = null;
        this.form.m2nev = null;
        this.form.emarka = null;
        this.form.defektt = null;
        this.form.xlfr = null;

        if (this.Homepage_model.season && this.Homepage_model.season.settings_value) {
            this.form.m1nev = this.Homepage_model.season.settings_value;
        }
    }
};
</script>

<style scoped></style>
