<template>
    <div>
        <Kiemelt />
        <section class="listSection">
            <div class="container">
                <!--<div class="flexrow justify-content-between h1-head">
                    <div
                        v-if="
                            model &&
                                model.season &&
                                model.season.settings_value === 'téligumi'
                        "
                    >
                        <span class="sub">
                            Népszerű
                        </span>
                        <h1>
                            téli gumik
                        </h1>
                        <hr />
                    </div>
                    <div v-else>
                        <span class="sub">
                            Népszerű
                        </span>
                        <h1>
                            nyári gumik
                        </h1>
                        <hr />
                    </div>
                    <template
                        v-if="
                            model &&
                                model.season &&
                                model.season.settings_value === 'téligumi'
                        "
                    >
                        <router-link
                            tag="button"
                            :to="{
                                name: 'ProductList',
                                params: { slug: 'téligumi' }
                            }"
                            class="grayButton transition"
                            >Összes téli gumi</router-link
                        >
                    </template>
                    <template v-else>
                        <router-link
                            tag="button"
                            :to="{
                                name: 'ProductList',
                                params: { slug: 'nyárigumi' }
                            }"
                            class="grayButton transition"
                            >Összes nyári gumi</router-link
                        >
                    </template>
                </div>-->
                <ProductRow
                    v-for="(item, index) in model.products"
                    v-bind:key="'prod-' + index"
                    :product-data="item"
                />
            </div>
        </section>
        <Hasznos />
        <Palyazat />
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ProductRow from "@/components/ProductRow";
import Palyazat from "@/components/Palyazat";
import Hasznos from "@/components/Hasznos";
import Kiemelt from "@/components/Kiemelt";

export default {
    name: "Home",
    components: { Kiemelt, Hasznos, Palyazat, ProductRow },
    data() {
        return {
            model: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    },
    beforeMount() {
        this.getSearchData();
    },
    methods: {
        getSearchData() {
            ApiService.get(`${this.url}/page/home`).then(response => {
                this.model = response.data;
                if (this.model.products && this.model.products.length > 0) {
                    this.model.products = this.model.products.sort(
                        (a, b) => a.netto - b.netto
                    );
                }
            });
        }
    }
};
</script>
