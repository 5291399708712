<template>
    <section class="articleSection">
        <div class="container">
            <div class="row fifty-rev">
                <div class="col-lg-6">
                    <span class="sub">
                        Hasznos információk, amit a
                    </span>
                    <h1>
                        Gumiabroncsokról tudni kell!
                    </h1>
                    <hr />
                    <p class="f-16">
                        <strong
                            >Az autógumi abroncs megfelelő állapota nagyon
                            fontos, hiszen a biztonságos közlekedés
                            elengedhetetlen. Nagyon fontos, hogy betartsuk az
                            abroncsok pontos cseréjét is, ami az időjárástól
                            függ.</strong
                        >
                    </p>
                    <p class="f-16">
                        A nyári gumikat lecserélni akkor érdemes, amikor a külső
                        hőmérséklet tartósan 7 celsius fok alá esik. Ha ez
                        megtörténik, érdemes azonnal lecserélnünk az
                        abroncsunkat a téli gumi szettre. A csere alkalmával
                        nagyon fontos, hogy minden egyes abroncsot felrakás
                        előtt átnézzük a gumikat. A megfelelő tárolás
                        következtében általában nem történik az állás során
                        bajuk, mégis érdemes a felületi sérülések miatt
                        alaposnak lenni. Ha a cserén túl vagyunk, minden esetben
                        nézzük meg az abroncsokban lévő nyomást. Valószínűleg
                        szükség lesz némi levegő utántöltésre, amit egy
                        kompresszor segítségével gyorsan megoldhatunk. Nagyon
                        figyeljünk arra, hogy megfelelő legyen a nyomás az
                        abroncsokban, hiszen ha ez az érték túl magas,
                        ugyanolyan ártalmas lehet, mint a túl alacsony értékek.
                        Ha nem tartunk otthon ilyen berendezést, bármelyik
                        üzemanyag töltő állomáson könnyedén megoldhatjuk ezt a
                        problémát. A levegő nyomását az abroncsokban ajánlott
                        legalább két hetente ellenőrizni, hiszen az alacsony
                        nyomás sokszor tönkre teheti a gumi szerkezetét, de még
                        a felnit is. A nyári abroncsunkat megfelelő helyen kell
                        tárolni a tél folyamán annak érdekében, hogy ne essen
                        baja. Sok gumis foglalkozik tárolással is, ami nagyban
                        megkönnyítheti a dolgunkat. Ilyenkor nincs is más
                        dolgunk, mint elvinni az autónkat a szakszervizbe, ahol
                        lecserélik az abroncsokat a raktáron lévőkre, amelyeket
                        az előző szezonban ott hagytunk. Így biztosak lehetünk
                        abban, hogy megfelelő környezetben és megfelelő
                        szaktudással tárolják az abroncsainkat. Ha nem okoz
                        számunkra problémát az abroncsok tárolása, akkor pedig
                        mindig figyeljünk arra, hogy ne legyen túl hideg a
                        tároló helyiségben, és nem is érje csapadék az
                        abroncsokat. Érdemes őket letakarva tárolni, ezzel is
                        megvédve az esetleges sérülésektől. Ha így járunk el,
                        biztosak lehetünk abban, hogy a tavasz beköszöntével
                        olyan kifogástalan állapotban találjunk abroncsainkat,
                        amilyenekben elraktuk őket.
                    </p>
                    <button
                        onclick="window.open('https://www.kellagumi.hu/hasznos-informaciok/', '_blank');"
                        class="classic"
                    >
                        Bővebben
                    </button>
                </div>
                <div class="col-lg-6">
                    <img width="100%" src="/images_dios/gumi_kep.png" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Hasznos"
};
</script>

<style scoped></style>
