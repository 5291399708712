var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row listElement",class:_vm.twocolumn},[_c('div',{staticClass:"col-lg-3 listImage"},[(
                _vm.productData.globalPattern && _vm.productData.globalPattern.image
            )?_c('img',{attrs:{"src":_vm.productData.globalPattern.image}}):_c('img',{attrs:{"src":_vm.productData.kepnev}})]),_c('div',{staticClass:"col-lg-3 flexevenly mainCol"},[_c('div',[_c('h3',{staticClass:"item-name"},[_vm._v(" "+_vm._s(_vm.productData.emarka)+" "+_vm._s(_vm.productData.pattern)+" "+_vm._s(_vm.productData.li)+_vm._s(_vm.productData.si)+" "+_vm._s(_vm.productData.xlfr)+" "+_vm._s(_vm.productData.m1nev)+" ")]),_c('span',{staticClass:"item-type"},[_vm._v(_vm._s(_vm.productData.width)+"/"+_vm._s(_vm.productData.profile)+"R"+_vm._s(_vm.productData.coll)),(_vm.productData.co_klt)?[_c('br'),_vm._v(" DOT SZÁM: "+_vm._s(_vm.productData.co_klt))]:_vm._e()],2)]),_c('div',{staticClass:"flexrow labels"},[_c('img',{attrs:{"src":"/images_dios/minoseg_eso.png"}}),_c('br'),_vm._v(_vm._s(_vm.productData.fekezes)+" "),_c('img',{attrs:{"src":"/images_dios/minoseg_fogyasztas.png"}}),_c('br'),_vm._v(_vm._s(_vm.productData.fogyasztas)+" "),_c('img',{attrs:{"src":"/images_dios/minoseg_zaj.png"}}),_c('br'),_vm._v(_vm._s(_vm.productData.zaj)+" ")]),_c('div',{staticClass:"flexrow"},[(
                    parseInt(_vm.productData.tat) > 0 ||
                        parseInt(_vm.productData.kulso_raktar) > 0
                )?_c('img',{staticClass:"katyugarancia",attrs:{"src":"/images_dios/raktaron.JPG"}}):_vm._e(),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],attrs:{"href":"javascript: void(0);"}},[(_vm.productData.mo === 'VAN')?_c('img',{staticClass:"katyugarancia",attrs:{"src":"/images_dios/katyugarancia.png"}}):_vm._e()])])]),_c('div',{staticClass:"col-lg-3 description verticalBottom"},[_vm._m(0),_c('ul',{staticClass:"twoColumns bold"},[(_vm.productData.defektt)?_c('li',[_vm._v("Igen")]):_c('li',[_vm._v("Nem")]),(_vm.productData.xlfr && _vm.productData.xlfr === 'XL')?_c('li',[_vm._v(" Igen ")]):_c('li',[_vm._v("Nem")]),_c('li',{style:(_vm.weightIncludes(_vm.productData))},[_vm._v(" "+_vm._s(_vm.weightIndex(_vm.productData.li))+" ")]),_c('li',[_vm._v(_vm._s(_vm.productData.si)+" "+_vm._s(_vm.speedIndex(_vm.productData.si)))]),_c('li',[_vm._v(_vm._s(_vm.productData.m1nev))]),_c('li',[_vm._v(_vm._s(_vm.getM2Nev(_vm.productData.m2nev)))])])]),_c('div',{staticClass:"col-lg-3 actions"},[_c('div',{staticClass:"prices"},[_c('span',{staticClass:"item-price sale",class:{
                    underlined: _vm.productData.szemar !== 0
                }},[_vm._v(" Ár: "+_vm._s(_vm.productData.calculated_special_price)+" / db ")]),(
                    parseInt(_vm.productData.tat) <= 6 ||
                        _vm.productData.tat === null ||
                        isNaN(_vm.productData.tat)
                )?[(
                        parseInt(_vm.productData.kulso_raktar) > 0 &&
                            (parseInt(_vm.productData.tat) <= 0 ||
                                isNaN(parseInt(_vm.productData.tat)))
                    )?_c('span',{staticClass:"lastOnes"},[_vm._v(" Beszállítói készlet")]):(_vm.productData.tat > 0)?_c('span',{staticClass:"lastOnes"},[_vm._v(" Utolsó "+_vm._s(_vm.productData.tat)+" db!")]):_vm._e()]:_vm._e()],2),_c('div',[_c('router-link',{staticClass:"transparent transition",attrs:{"to":{
                    name: 'Product',
                    params: {
                        slug: _vm.productData.kod2,
                        emarka: _vm.productData.emarka,
                        width: _vm.productData.width,
                        profile: _vm.productData.profile,
                        coll: _vm.productData.coll
                    }
                },"tag":"button"}},[_vm._v(" Részletek ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quantity),expression:"quantity"}],staticClass:"form-control list-input-padding",attrs:{"type":"number","id":"count","name":"count","step":"1","placeholder":"darab","min":"1"},domProps:{"value":(_vm.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.quantity=$event.target.value}}}),_c('button',{staticClass:"toCart transition",on:{"click":_vm.addCart}},[_vm._v(" Kosárba"),_c('i',{staticClass:"icon-basket"})])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"twoColumns first"},[_c('li',[_vm._v("Defekttűrő")]),_c('li',[_vm._v("Erősített oldalfal (XL)")]),_c('li',[_vm._v("Súly index")]),_c('li',[_vm._v("Sebesség index")]),_c('li',[_vm._v("Szezon")]),_c('li',[_vm._v("Gépjármű")])])}]

export { render, staticRenderFns }