<template>
    <div class="row listElement" v-bind:class="twocolumn">
        <div class="col-lg-3 listImage">
            <img
                v-if="
                    productData.globalPattern && productData.globalPattern.image
                "
                :src="productData.globalPattern.image"
            />
            <img v-else :src="productData.kepnev" />
        </div>
        <div class="col-lg-3 flexevenly mainCol">
            <div>
                <h3 class="item-name">
                    {{ productData.emarka }} {{ productData.pattern }}
                    {{ productData.li }}{{ productData.si }}
                    {{ productData.xlfr }}
                    {{ productData.m1nev }}
                </h3>
                <span class="item-type"
                    >{{ productData.width }}/{{ productData.profile }}R{{
                        productData.coll
                    }}<template v-if="productData.co_klt"
                        ><br />
                        DOT SZÁM: {{ productData.co_klt }}</template
                    ></span
                >
            </div>
            <div class="flexrow labels">
                <img src="/images_dios/minoseg_eso.png" /><br />{{
                    productData.fekezes
                }}
                <img src="/images_dios/minoseg_fogyasztas.png" /><br />{{
                    productData.fogyasztas
                }}
                <img src="/images_dios/minoseg_zaj.png" /><br />{{
                    productData.zaj
                }}
                <!--<span class="stock">{{ productData.tat }} db</span>-->
            </div>
            <div class="flexrow">
                <img
                    class="katyugarancia"
                    src="/images_dios/raktaron.JPG"
                    v-if="
                        parseInt(productData.tat) > 0 ||
                            parseInt(productData.kulso_raktar) > 0
                    "
                />
                <a href="javascript: void(0);" v-b-modal.modal-1>
                    <img
                        v-if="productData.mo === 'VAN'"
                        class="katyugarancia"
                        src="/images_dios/katyugarancia.png"
                    />
                </a>
            </div>
        </div>
        <!--<img
            class="akcio"
            src="/images_dios/akciobadge.JPG"
            v-if="
                parseInt(productData.special_price) !== 0 &&
                    parseInt(productData.special_price) !==
                        parseInt(productData.netto)
            "
        />-->
        <div class="col-lg-3 description verticalBottom">
            <ul class="twoColumns first">
                <li>Defekttűrő</li>
                <li>Erősített oldalfal (XL)</li>
                <li>Súly index</li>
                <li>Sebesség index</li>
                <li>Szezon</li>
                <li>Gépjármű</li>
            </ul>
            <ul class="twoColumns bold">
                <li v-if="productData.defektt">Igen</li>
                <li v-else>Nem</li>
                <li v-if="productData.xlfr && productData.xlfr === 'XL'">
                    Igen
                </li>
                <li v-else>Nem</li>
                <li v-bind:style="weightIncludes(productData)">
                    {{ weightIndex(productData.li) }}
                </li>
                <li>{{ productData.si }} {{ speedIndex(productData.si) }}</li>
                <li>{{ productData.m1nev }}</li>
                <li>{{ getM2Nev(productData.m2nev) }}</li>
            </ul>
        </div>
        <div class="col-lg-3 actions">
            <div class="prices">
                <span
                    class="item-price sale"
                    v-bind:class="{
                        underlined: productData.szemar !== 0
                    }"
                >
                    Ár: {{ productData.calculated_special_price }} / db
                </span>
                <template
                    v-if="
                        parseInt(productData.tat) <= 6 ||
                            productData.tat === null ||
                            isNaN(productData.tat)
                    "
                    ><span
                        class="lastOnes"
                        v-if="
                            parseInt(productData.kulso_raktar) > 0 &&
                                (parseInt(productData.tat) <= 0 ||
                                    isNaN(parseInt(productData.tat)))
                        "
                    >
                        Beszállítói készlet</span
                    >
                    <span class="lastOnes" v-else-if="productData.tat > 0">
                        Utolsó {{ productData.tat }} db!</span
                    ></template
                >
            </div>

            <div>
                <router-link
                    class="transparent transition"
                    :to="{
                        name: 'Product',
                        params: {
                            slug: productData.kod2,
                            emarka: productData.emarka,
                            width: productData.width,
                            profile: productData.profile,
                            coll: productData.coll
                        }
                    }"
                    tag="button"
                >
                    Részletek
                </router-link>
                <input
                    type="number"
                    id="count"
                    name="count"
                    step="1"
                    placeholder="darab"
                    min="1"
                    class="form-control list-input-padding"
                    v-model="quantity"
                />
                <button class="toCart transition" v-on:click="addCart">
                    Kosárba<i class="icon-basket"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import swal from "sweetalert2";

export default {
    name: "ProductRow",
    props: ["productData", "noCompare", "twocolumn"],
    data() {
        return {
            isCompare: false,
            quantity: null,
            speed_table: {},
            weight_table: {}
        };
    },
    watch: {
        isCompare: function(val) {
            this.$emit("setCompareId", {
                checked: val,
                id: this.productData.id
            });
        }
    },
    methods: {
        weightIncludes(productData) {
            if (
                productData &&
                productData.li &&
                this.weightIndex(productData.li) &&
                this.weightIndex(productData.li).includes("/")
            ) {
                return "margin-left: -40px;";
            }
            return "";
        },
        getM2Nev(type) {
            switch (type) {
                case "SZGK.":
                    return "Személygépkocsi";
                case "4X4":
                    return "4X4,SUV";
                case "TEHER":
                    return "Kisteherautó";
                default:
                    return type;
            }
        },
        addCart() {
            const self = this;
            let modal = false;
            let title = "";
            let text = "";
            const tat =
                self.productData.tat === null ? 0 : self.productData.tat;
            const kulso_raktar =
                self.productData.kulso_raktar === null
                    ? 0
                    : self.productData.kulso_raktar;
            if (parseInt(tat) < 1 && parseInt(kulso_raktar) > 0) {
                modal = true;
                title = self.$t(
                    "A kért mennyiséget külső raktárról egészítjük ki!"
                );
                text = self.$t(
                    "A rendelésével kapcsolatban kollégáink hamarosan hívni fogják."
                );
            } else if (
                parseInt(self.quantity) >
                parseInt(tat) + parseInt(kulso_raktar)
            ) {
                modal = true;
                title = self.$t("A kért mennyiségből nincs elég raktáron!");
                text = self.$t(
                    "A rendelésével kapcsolatban kollégáink hamarosan hívni fogják."
                );
            }

            if (modal) {
                swal.fire({
                    title: title,
                    text: text,
                    type: "warning",
                    showCancelButton: !0,
                    confirmButtonText: self.$t("Igen, megrendelem!"),
                    cancelButtonText: self.$t("Mégse."),
                    reverseButtons: !0
                }).then(function(e) {
                    if (e.value) {
                        self.addToCart(self.productData.id, self.quantity);
                    }
                });
            } else {
                self.addToCart(self.productData.id, self.quantity);
            }
        },

        weightIndex(indexinput) {
            if (this.weight_table[indexinput]) {
                return (
                    indexinput + " (" + this.weight_table[indexinput] + " kg)"
                );
            }
            if (indexinput.includes("/")) {
                indexinput = indexinput.replaceAll(" ", "");
                let weight_index_array = indexinput.split("/");
                let returnstring = String(indexinput) + " = ";
                for (let i = 0; i < weight_index_array.length; i++) {
                    returnstring =
                        returnstring +
                        this.weight_table[weight_index_array[i]] +
                        " kg";
                    if (i === 0) {
                        returnstring = returnstring + "/";
                    }
                }
                return returnstring;
            }
        },

        speedIndex(indexinput) {
            if (this.speed_table[indexinput]) {
                return "(" + this.speed_table[indexinput] + " km/h)";
            }
        }
    },
    mounted() {
        if (this.productData.tat > 4) {
            this.quantity = 4;
        } else {
            // this.quantity = this.productData.tat;
            this.quantity = 4;
        }

        this.speed_table = {
            A1: "5",
            A2: "10",
            A3: "15",
            A4: "20",
            A5: "25",
            A6: "30",
            A7: "35",
            A8: "40",
            B: "50",
            C: "60",
            D: "65",
            E: "70",
            F: "80",
            G: "90",
            J: "100",
            K: "110",
            L: "120",
            M: "130",
            N: "140",
            P: "150",
            Q: "160",
            R: "170",
            S: "180",
            T: "190",
            U: "200",
            H: "210",
            V: "240",
            ZR: "240 felett",
            W: "270",
            Y: "300",
            "(Y)": "300 felett"
        };
        this.weight_table = {
            "50": "190",
            "51": "195",
            "52": "200",
            "53": "206",
            "54": "212",
            "55": "218",
            "56": "224",
            "57": "230",
            "58": "236",
            "59": "243",
            "60": "250",
            "61": "257",
            "62": "265",
            "63": "272",
            "64": "280",
            "65": "290",
            "66": "300",
            "67": "307",
            "68": "315",
            "69": "325",
            "70": "335",
            "71": "345",
            "72": "355",
            "73": "365",
            "74": "375",
            "75": "387",
            "76": "400",
            "77": "412",
            "78": "425",
            "79": "437",
            "80": "450",
            "81": "462",
            "82": "475",
            "83": "487",
            "84": "500",
            "85": "515",
            "86": "530",
            "87": "545",
            "88": "560",
            "89": "580",
            "90": "600",
            "91": "615",
            "92": "630",
            "93": "650",
            "94": "670",
            "95": "690",
            "96": "710",
            "97": "730",
            "98": "750",
            "99": "775",
            "100": "800",
            "101": "825",
            "102": "850",
            "103": "875",
            "104": "900",
            "105": "925",
            "106": "950",
            "107": "975",
            "108": "1000",
            "109": "1030",
            "110": "1060",
            "111": "1090",
            "112": "1120",
            "113": "1150",
            "114": "1180",
            "115": "1215",
            "116": "1250",
            "117": "1285",
            "118": "1320",
            "119": "1360",
            "120": "1400",
            "121": "1450",
            "122": "1500",
            "123": "1550",
            "124": "1600",
            "125": "1650",
            "126": "1700",
            "127": "1750",
            "128": "1800",
            "129": "1850",
            "130": "1900",
            "131": "1950",
            "132": "2000",
            "133": "2060",
            "134": "2120",
            "135": "2180",
            "136": "2240",
            "137": "2300",
            "138": "2360",
            "139": "2430",
            "140": "2500",
            "141": "2575",
            "142": "2650",
            "143": "2725",
            "144": "2800",
            "145": "2900",
            "146": "3000",
            "147": "3075",
            "148": "3150",
            "149": "3250",
            "150": "3350",
            "151": "3450",
            "152": "3550",
            "153": "3650",
            "154": "3750",
            "155": "3875",
            "156": "4000",
            "157": "4125",
            "158": "4250",
            "159": "4375",
            "160": "4500",
            "161": "4625",
            "162": "4750",
            "163": "4875",
            "164": "5000",
            "165": "5150",
            "166": "5300",
            "167": "5450",
            "168": "5600",
            "169": "5800"
        };
        console.log(this.speed_table);
        console.log(this.weight_table);
    },
    computed: {
        appUrl: function() {
            return process.env.VUE_APP_APP_DIOS_URL;
        },
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    }
};
</script>

<style>
.underlined {
    text-decoration: underline !important;
}
</style>
