<template>
    <section class="partnerSection">
        <div class="container">
            <h1>Pályázatok</h1>
            <hr />
            <div class="d-flex justify-content-between align-items-center">
                <div class="col-lg-4">
                    <img src="/images_dios/Diosgumi.hu_ginop_4_1_4_19_2020_00472_c_web-1.jpg" />
                </div>
                <div class="col-lg-4">
                    <img src="/images_dios/Diosgumi.hu_ginop_4_1_4_19_2020_00472_c_web-2.jpg" />
                </div>
                <div class="col-lg-4">
                    <img src="/images_dios/Diosgumi.hu_ginop_4_1_4_19_2020_00472_c_web-3.jpg" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Palyazat"
};
</script>

<style scoped></style>
